import * as React from "react"
import cursorsecondaryicn from '../../assets/img/cursor-secondary-icn.svg';

const EnrollOnline = () => {
	return (
		<div className="secondary-cta">
			<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
			<p className="head"><b>Ready to enroll?</b></p>
			<a href="https://www.dialamerica.com/mvp/" className="button secondary">Enroll Online</a>
			<p class="bottom-copy">Or download your application: <a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/medicare-advantage-individual-enrollment-application-uvm-2024.pdf" class="download">Download the UVM Health Advantage 2024 Application (PDF)</a></p>
		</div>
	)
}

export default EnrollOnline;
